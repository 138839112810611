.card-imgs{
    /* margin: 10%; */
    margin: 5%;
    width: 90%;
    overflow: hidden;
}
.imgs:hover{
    transition: transform .8s;
    transition-timing-function: ease-out;
    transform: scale(1.2);
}
.card-custom{
    overflow: hidden;
}
.card-custom:hover{
    position: relative;
    top: -6px;
}