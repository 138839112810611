*{
    margin: 0;padding: 0;
}

.navbar {
    transition: all 0.3s ease-in-out;
    padding: 1rem 1rem;
  }
  
  .navbar.scrolled {
    padding: 0.5rem 1rem; /* Reduce padding */
  }
  
  .navbar-brand {
    font-size: 1.5rem;
    transition: font-size 0.3s ease-in-out;
  }
  
  .navbar.scrolled .navbar-brand {
    font-size: 1.2rem; /* Scale down brand font */
  }
  

.custom{
    height: 70px;
    text-transform: uppercase;
    /* box-shadow: 1px 6px 2px 1px gray; */
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
	
    .font96{
        font-size: 56px;;
    }
    
    .animation{
        width: 90%;
        margin: auto;
    }
    .customContainer{
        display: grid;
        margin: 100px auto;
    }
    .contant{
        width: 90%;
        /* background-color: yellow; */
        margin: 100px auto;
        display: grid;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    span:nth-child(1){
        font-size: 24px;
    }
    span:nth-child(2){
        font-size: 40px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
    body {
        height: 100%;
    }
    .font96{
        font-size: 38px;;
    }
    .animation{
        width: 90%;
        margin: auto;
    }
    .customContainer{
        display: grid;
        margin: 100px auto;
    }
    .contant{
        width: 90%;
        margin: 100px auto;
        display: grid;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    span:nth-child(1){
        font-size: 22px;
    }
    span:nth-child(2){
        font-size: 36px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
    span:nth-child(1){
        font-size: 24px;
    }
    span:nth-child(2){
        font-size: 42px;
    }
}