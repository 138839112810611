.product-img{
    min-width: 130px;
    min-height: 100px;
    width: 30%;
    max-height: 200px;
    float: left;
    position: relative;
    object-fit: scale-down;
}
.box{
    display: flex;
    /* justify-content: flex-start; */
    /* background-color: hotpink; */
}
.product-contant{
    /* display: flex; */
    /* margin: 100px; */
    margin: 20px 40px;
    
}

.btn{
    padding: 5px 20px;
    background-color: black;
    color: white;
}
